import GoogleIcon from './../assets/icons/google.png';
import DropHouseLogo from './../assets/logo_footer_t.png';
import FacebookIcon from './../assets/icons/facebook.png';
import AppleIcon from './../assets/icons/apple.png';

function OrderIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21V13M12 21L4.94 16.5875C4.48048 16.3003 4.25072 16.1567 4.12536 15.9305C4 15.7043 4 15.4334 4 14.8915V8M12 21L16 18.5L19.06 16.5875C19.5195 16.3003 19.7493 16.1567 19.8746 15.9305C20 15.7043 20 15.4334 20 14.8915V8M12 13L4 8M12 13L20 8M4 8L10.94 3.6625C11.4555 3.34033 11.7132 3.17925 12 3.17925C12.2868 3.17925 12.5445 3.34033 13.06 3.6625L20 8"
          stroke="#6E6E73"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          d="M15 14.5C15 15.0523 15.4477 15.5 16 15.5C16.5523 15.5 17 15.0523 17 14.5H15ZM15.8746 10.5695L16.7493 10.0847L15.8746 10.5695ZM7.47 6.348L14.53 10.7605L15.59 9.0645L8.53 4.652L7.47 6.348ZM17 14.5V11.6085H15V14.5H17ZM14.53 10.7605C14.7757 10.9141 14.9004 10.9932 14.9843 11.0575C15.0512 11.1089 15.027 11.1029 15 11.0542L16.7493 10.0847C16.5969 9.80984 16.3951 9.61902 16.201 9.47022C16.0238 9.33438 15.8038 9.19813 15.59 9.0645L14.53 10.7605ZM17 11.6085C17 11.3564 17.0011 11.0976 16.9798 10.8753C16.9565 10.6319 16.9016 10.3596 16.7493 10.0847L15 11.0542C14.973 11.0056 14.9808 10.9818 14.9889 11.0659C14.9989 11.1711 15 11.3187 15 11.6085H17Z"
          fill="#6E6E73"
        />
      </svg>
    );
  }
  
  function HeartIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 19.628L5.13535 13.1794C3.55512 11.6949 3.36322 9.25207 4.69227 7.53907L5.00163 7.14034C6.51358 5.19161 9.54846 5.51842 10.6109 7.74438C11.1685 8.91272 12.8315 8.91272 13.3891 7.74438C14.4515 5.51842 17.4864 5.1916 18.9984 7.14034L19.3077 7.53906C20.6368 9.25207 20.4449 11.6949 18.8646 13.1794L12 19.628Z"
          stroke="#6E6E73"
          strokeWidth="2"
        />
      </svg>
    );
  }
  
  function ShopCartIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.46 19.59C10.462 19.9069 10.3698 20.2172 10.1952 20.4816C10.0206 20.746 9.77144 20.9527 9.47925 21.0753C9.18707 21.198 8.86505 21.2311 8.55403 21.1705C8.243 21.1099 7.95698 20.9583 7.73221 20.7349C7.50745 20.5115 7.35406 20.2265 7.29151 19.9158C7.22896 19.6052 7.26006 19.283 7.38086 18.99C7.50166 18.6971 7.70672 18.4466 7.97006 18.2704C8.23339 18.0941 8.54312 18 8.86 18C9.28262 18 9.68808 18.1672 9.98785 18.4651C10.2876 18.763 10.4574 19.1674 10.46 19.59ZM17.05 18C16.7335 18 16.4242 18.0938 16.1611 18.2696C15.898 18.4455 15.6929 18.6953 15.5718 18.9877C15.4507 19.2801 15.419 19.6018 15.4807 19.9121C15.5425 20.2225 15.6949 20.5076 15.9186 20.7314C16.1424 20.9551 16.4275 21.1075 16.7379 21.1693C17.0482 21.231 17.3699 21.1993 17.6623 21.0782C17.9547 20.9571 18.2045 20.752 18.3804 20.4889C18.5562 20.2258 18.65 19.9164 18.65 19.6C18.65 19.1757 18.4814 18.7687 18.1814 18.4686C17.8813 18.1686 17.4743 18 17.05 18ZM20.44 8.25L18.82 14.74C18.6554 15.3867 18.2799 15.9601 17.7528 16.3694C17.2258 16.7787 16.5773 17.0006 15.91 17H10C9.33135 17 8.68185 16.7767 8.15463 16.3654C7.62741 15.9542 7.2527 15.3786 7.09 14.73L4.69 5H3.1C2.83478 5 2.58043 4.89464 2.39289 4.7071C2.20536 4.51957 2.1 4.26521 2.1 4C2.1 3.73478 2.20536 3.48043 2.39289 3.29289C2.58043 3.10535 2.83478 3 3.1 3H5.47C5.69843 2.99334 5.92226 3.06513 6.1042 3.20341C6.28615 3.34169 6.41525 3.53812 6.47 3.76L7.25 7H19.35H19.44C19.5327 6.98522 19.6272 6.98522 19.72 7C19.8497 7.03246 19.9715 7.0906 20.0783 7.17097C20.1852 7.25134 20.2748 7.35231 20.3419 7.46792C20.409 7.58352 20.4523 7.71141 20.4691 7.84402C20.486 7.97664 20.4761 8.11128 20.44 8.24V8.25ZM18.19 9H7.75L9.06 14.24C9.11228 14.4519 9.23244 14.6409 9.40217 14.7781C9.57189 14.9153 9.78185 14.9933 10 15H15.88C16.1084 15.0067 16.3323 14.9349 16.5142 14.7966C16.6962 14.6583 16.8252 14.4619 16.88 14.24L18.19 9Z"
          fill="#808080"
        />
      </svg>
    );
  }
  
  function AccountIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.7274 20.4471C19.2716 19.1713 18.2672 18.0439 16.8701 17.2399C15.4729 16.4358 13.7611 16 12 16C10.2389 16 8.52706 16.4358 7.12991 17.2399C5.73276 18.0439 4.72839 19.1713 4.27259 20.4471"
          stroke="#6E6E73"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <circle
          cx="12"
          cy="8"
          r="4"
          stroke="#6E6E73"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }
  
  function SignInIcon() {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 7.13193V6.61204C7 4.46614 7 3.3932 7.6896 2.79511C8.37919 2.19703 9.44136 2.34877 11.5657 2.65224L15.8485 3.26408C18.3047 3.61495 19.5327 3.79039 20.2664 4.63628C21 5.48217 21 6.72271 21 9.20377V14.7962C21 17.2773 21 18.5178 20.2664 19.3637C19.5327 20.2096 18.3047 20.385 15.8485 20.7359L11.5657 21.3478C9.44136 21.6512 8.37919 21.803 7.6896 21.2049C7 20.6068 7 19.5339 7 17.388V17.066"
          stroke="#6E6E73"
          strokeWidth="2"
        />
        <path
          d="M16 12L16.7809 11.3753L17.2806 12L16.7809 12.6247L16 12ZM4 13C3.44771 13 3 12.5523 3 12C3 11.4477 3.44771 11 4 11V13ZM12.7809 6.3753L16.7809 11.3753L15.2191 12.6247L11.2191 7.6247L12.7809 6.3753ZM16.7809 12.6247L12.7809 17.6247L11.2191 16.3753L15.2191 11.3753L16.7809 12.6247ZM16 13H4V11H16V13Z"
          fill="#6E6E73"
        />
      </svg>
    );
  }

export {GoogleIcon, DropHouseLogo, FacebookIcon, AppleIcon, OrderIcon, HeartIcon, ShopCartIcon, AccountIcon, SignInIcon};